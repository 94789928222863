<template>
    <div class="table-container">
        <MDBTable hover class="align-middle">
            <thead class="table-light">
                <tr>
                    <th v-for="(item, i) in tableHeaders" :key="i" scope="col">
                        {{ item.header }}
                    </th>
                </tr>
            </thead>
            <tbody v-if="loader">
                <div class="backdrop">
                    <div class="image-container d-flex justify-content-center align-items-center">
                        <div class="loader"></div>
                        <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
                    </div>
                </div>
            </tbody>
            <tbody v-if="monthlyReport.length < 1">
                <tr>
                    <td colspan="8">No report available.</td>
                </tr>
            </tbody>
            <tbody v-else-if="monthlyReport.length > 0">
                <tr v-for="(item, i) in monthlyReport" :key="i">
                    <td>{{ item.fullName }}</td>
                    <td>{{ getMonthFullFormat(item.month) }}</td>
                    <td>{{ item.year }}</td>
                    <td>{{ item.partnerPercentage }}%</td>
                    <td>${{ addCommaNumbers(item.adjustments.toFixed(2)) }}</td>
                    <td>${{ addCommaNumbers(item.grossRevenue.toFixed(2)) }}</td>
                    <td>${{ addCommaNumbers(item.handlerRevenue.toFixed(2)) }}</td>
                    <td>${{ addCommaNumbers(item.netRevenue.toFixed(2)) }}</td>
                </tr>
            </tbody>
        </MDBTable>
    </div>
</template>

<script>
import { MDBTable } from "mdb-vue-ui-kit";
import { useMonthlyReportStore } from "@/store/monthlyReport";
import { storeToRefs } from "pinia";
import { computed } from "@vue/runtime-core";
import { getMonthFullFormat } from "@/helpers/parseDate";
import { addCommaNumbers } from "@/helpers/addCommaNumbers";
export default {
    components: {
        MDBTable,
    },
    setup() {
        const monthlyReportStore = useMonthlyReportStore();
        const tableHeaders = computed(() => {
            return [
                {
                    header: "Username",
                },
                {
                    header: "Month",
                },
                {
                    header: "Year",
                },
                {
                    header: "Partner Percentage",
                },
                {
                    header: "Adjustments",
                },
                {
                    header: "Gross Revenue",
                },
                {
                    header: "Handler Revenue",
                },
                {
                    header: "Net Revenue",
                },
            ];
        });
        const { monthlyReport, loader } = storeToRefs(monthlyReportStore);
        return {
            tableHeaders,
            monthlyReport,
            getMonthFullFormat,
            addCommaNumbers,
            loader,
        };
    },
};
</script>

<style lang="scss" scoped>
.table-container {
    border-radius: 4px;
    overflow-x: auto;

    :deep(.table) {
        margin-bottom: 0;

        caption {
            font-size: 1rem;
            padding-left: 20px;
        }
    }
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
